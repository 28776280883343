import {useEffect, useMemo, useState} from 'react'
import { API } from "@stoplight/elements";
import '@stoplight/elements/styles.min.css';
import {useWindowWidth} from '@react-hook/window-size'

import './App.css';
import Feedback from './Feedback.js';
import Loading from './Loading.js';

// Uncomment and add src/schema.json to use local schema
// import schema from './schema.json'

function App() {
  const [loading, setLoading] = useState(true)

  const width = useWindowWidth()
  const layout = useMemo(() => width > 780 ? 'sidebar' : 'stacked', [width])

  // Uncomment to use direct Git link instead of caching through Pages functions
  // const url = "https://raw.githubusercontent.com/cloudflare/api-schemas/json/openapi.json"
  const url = "/schema"

  const [schema, setSchema] = useState({})

  // If using local schema file, uncomment the following lines of code
  // and comment the useEffect block below
  // setSchema(schema)
  // setLoading(false)

  useEffect(() => {
    fetch(url).then(resp => resp.text().then(schema => {
      setSchema(schema)
      setLoading(false)
    }))
  }, [])

  return (
    <div style={{height: '100vh'}}>
      {loading ?
        <Loading /> :
        <>
          <Feedback />
          <API
            apiDescriptionDocument={schema}
            basePath={"/api"}
            hideSchemas
            layout={layout}
            logo='/logo.svg'
            tryItCorsProxy={"https://cloudflare-openapi-proxy.cloudflare-docs.workers.dev/"}
          />
        </>
      }
    </div>
  );
}

export default App;
